var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('reactive-base',{attrs:{"app":"ticket_index","credentials":_vm.user.user.es_username + ':' + _vm.user.user.es_password,"url":_vm.elasticUrl}},[_c('div',{staticClass:"col-lg-12 col-xl-12 order-lg-1 order-xl-1"},[_c('div',{staticClass:"widget"},[_c('div',{staticClass:"widget-header"},[_c('div',{staticClass:"widget-header-label"},[_c('h3',[_vm._v(_vm._s(_vm.title)+" "),_c('info-box',{attrs:{"field":_vm.infoBox}}),_c('small')],1)]),_c('div',{staticClass:"widget-header-toolbar"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableFilter),expression:"enableFilter"}],staticClass:"actions"},[_c('div',{staticClass:"filter-action filter-action-lg"},[_c('multi-dropdown-list',{attrs:{"placeholder":this.$t('labels.victim-support-centre'),"componentId":"officeFilter","URLParams":true,"sortBy":"asc","fuzziness":"AUTO","size":100,"showSearch":true,"filterLabel":this.$t('labels.victim-support-centres'),"dataField":"office.raw","react":{
										and: [
											'SearchResult',
											'createdDateFilters',
											'localofficeFilter',
											'userSubFilter',
											'prioritySubFilter',
											'ageSubFilter',
											'riskFilter',
											'categorySubFilter',
											'crimeSubFilter',
											'statusSubFilter',
											'languageSubFilter' ],
									}}})],1),_c('div',{staticClass:"filter-action filter-action-lg ml-3"},[_c('multi-dropdown-list',{attrs:{"placeholder":this.$t('labels.local-office'),"componentId":"localofficeFilter","filterLabel":this.$t('labels.local-office'),"dataField":"localoffice.raw","sortBy":"asc","size":100,"URLParams":true,"showSearch":true,"react":{
										and: [
											'officeFilter',
											'createdDateFilters',
											'userSubFilter',
											'prioritySubFilter',
											'ageSubFilter',
											'riskFilter',
											'categorySubFilter',
											'crimeSubFilter',
											'statusSubFilter',
											'languageSubFilter' ],
									}}})],1),_c('div',{staticClass:"filter-action-sm ml-3"},[_c('ReactiveComponent',{attrs:{"componentId":"createdDateFilters","URLParams":true,"filterLabel":this.$t('labels.created-at'),"react":{ and: [
                            'SearchResult',
                            'localofficeFilter',
                            'userSubFilter',
                            'prioritySubFilter',
                            'ageSubFilter',
                            'riskFilter',
                            'categorySubFilter',
                            'crimeSubFilter',
                            'statusSubFilter',
                            'languageSubFilter' ],  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var aggregations = ref.aggregations;
                          var setQuery = ref.setQuery;
                          var value = ref.value;
return _c('div',{},[_c('date-picker',{attrs:{"opens":"left","placeholder":_vm.$t('labels.created-at'),"aggregations":aggregations,"setQuery":setQuery,"selectedValue":value,"show-dropdowns":true,"dataField":"created_at"}})],1)}}])})],1),_c('div',{staticClass:"filter-action ml-3 filter-action-sm"},[_c('multi-dropdown-list',{attrs:{"placeholder":this.$t('labels.priority'),"componentId":"prioritySubFilter","sortBy":"asc","size":100,"dataField":_vm.$field('priority')+'.raw',"filterLabel":this.$t('labels.priority'),"URLParams":true,"react":{
										and: [
											'officeFilter',
											'createdDateFilters',
											'localofficeFilter',
											'genderSubFilter',
											'ageSubFilter',
											'riskFilter',
											'userSubFilter',
											'categorySubFilter',
											'crimeSubFilter',
											'languageSubFilter' ],
									}}})],1),_c('div',{staticClass:"filter-action ml-3 filter-action"},[_c('multi-dropdown-list',{attrs:{"placeholder":this.$t('labels.administrator'),"componentId":"userSubFilter","sortBy":"asc","size":1000,"dataField":"users.name.raw","nestedField":"users","filterLabel":this.$t('labels.administrator'),"URLParams":true,"react":{
										and: [
											'officeFilter',
											'createdDateFilters',
											'localofficeFilter',
											'genderSubFilter',
											'ageSubFilter',
											'prioritySubFilter',
											'riskFilter',
											'categorySubFilter',
											'crimeSubFilter',
											'languageSubFilter' ],
									}}})],1)])])]),_c('div',{staticClass:"widget-body no-padding"},[_c('selected-filters',{staticClass:"bb-1 curent-filters pl-4",attrs:{"clearAllLabel":this.$t('labels.clear')}})],1),_c('div',{staticClass:"widget-body no-padding"},[_c('reactive-component',{attrs:{"componentId":"myColorPicker","react":{
							and: [
								'riskPlaceholder',
								'languageSubFilter',
								'officeFilter',
								'prioritySubFilter',
								'localofficeFilter',
								'genderSubFilter',
								'ageSubFilter',
								'prioritySubFilter',
								'riskFilter',
								'categorySubFilter',
								'userSubFilter',
								'crimeSubFilter',
								'statusSubFilter',
                'createdDateFilters' ],
						},"defaultQuery":function () { return ({
								aggs: {
									users: {
										nested: {
											path: 'users',
										},
										aggs: {
											name: {
												terms: {
													field: 'users.name.raw',
													size: 100,
                          'order': { '_key': 'asc' }
												},
												aggs: {
                          supporter_status_tag: {
                            terms: {
                              field: 'users.supporter_status_tag.raw',
                              size: 1,
                              min_doc_count: 0,
                            },
                          },
													role: {
														terms: {
															field: 'users.'+_vm.$field('ticket_role')+'.raw',
															size: 100,
														},

														aggs: {
															status: {
																reverse_nested: {},
																aggs: {
																	tickets: {
																		terms: {
																			field: _vm.$field('ticketstatus')+'.raw',
																			size: 100,
																		},
																	},
																},
															},
														},
													},
												},
											},
										},
									},
								},
							}); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var aggregations = ref.aggregations;
							var hits = ref.hits;
							var setQuery = ref.setQuery;
return _c('div',{},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},_vm._l((aggregations.users.name.buckets),function(agg){return _c('table',{key:agg.key,staticClass:"vuetable"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"padding-left":"20px"},attrs:{"width":"20%"}},[(agg.supporter_status_tag.buckets[0].doc_count > 0)?_c('status-icon',{staticStyle:{"position":"absolute","left":"20px"},attrs:{"supporterStatusTag":agg.supporter_status_tag.buckets[0].key}}):_vm._e(),_vm._v(" "+_vm._s(agg.key))],1),_c('th',{attrs:{"width":"13%"}},[_vm._v(_vm._s(_vm.$t('labels.total')))]),_vm._l((_vm.ticketStatus),function(status){return _c('th',{key:status.id,style:({ color: status.color }),attrs:{"width":"13%"}},[_vm._v(" "+_vm._s(_vm.$to(status,'label'))+" ")])})],2)]),_vm._l((agg.role.buckets),function(agg_role){return _c('tbody',{key:agg_role.key},[_c('tr',[_c('td',{staticStyle:{"padding-left":"20px"}},[_vm._v(_vm._s(agg_role.key))]),_c('td',[_vm._v(_vm._s(agg_role.doc_count))]),_vm._l((_vm.ticketStatus),function(status){return _c('td',{key:agg.key + status.id},[_vm._v(" "+_vm._s(_vm.getNumberOfTicketsFromStatus(_vm.$to(status,'label'), agg_role.status.tickets.buckets))+" ")])})],2)])})],2)}),0)])])}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }