<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <h3>
            {{ $t('labels.users-tickets') }}
          </h3>
          <span class="separator-line"></span>
          <div class="breadcrumbs">
            <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
            <span class="separator-dot"></span>
            <router-link to="/tickets/users" class="breadcrumbs-link"> {{ $t('labels.users-tickets') }}</router-link>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <tickets-list
            :enable-filter="$store.getters.permissions.includes('tickets-page-users-use-filter')"
            :defaultQuery="query"
            :title="this.$t('labels.users-tickets')"
            :infoBox="'Ärenden avd Handläggare - rubrik'"
        ></tickets-list>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import Notifications from '@/components/dashboard/Notifications.vue';
import Multiselect from 'vue-multiselect';
import TicketsList from '@/components/lists/ticket/TicketsListUsers.vue';
import InfoBox from '@/components/InfoBox.vue';

export default {
  name: 'home',
  components: {
    Multiselect,
    LayoutMain,
    Notifications,
    TicketsList,
    InfoBox,
  },
  data() {
    return {
      query: {
        query: {},
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
