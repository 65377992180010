<template>
  <reactive-base
      app="ticket_index"
      :credentials="user.user.es_username + ':' + user.user.es_password"
      :url="elasticUrl"
  >
    <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
      <div class="widget">
        <div class="widget-header">
          <div class="widget-header-label">
            <h3>{{ title }} <info-box :field="infoBox" /><small></small></h3>

          </div>
          <div class="widget-header-toolbar">
            <div class="actions" v-show="enableFilter">
              <div class="filter-action filter-action-lg">
                <multi-dropdown-list
                    :placeholder="this.$t('labels.victim-support-centre')"
                    componentId="officeFilter"
                    :URLParams="true"
                    sortBy="asc"
                    fuzziness="AUTO"
                    :size="100"
                    :showSearch="true"
                    :filterLabel="this.$t('labels.victim-support-centres')"
                    dataField="office.raw"
                    :react="{
										and: [
											'SearchResult',
											'createdDateFilters',
											'localofficeFilter',
											'userSubFilter',
											'prioritySubFilter',
											'ageSubFilter',
											'riskFilter',
											'categorySubFilter',
											'crimeSubFilter',
											'statusSubFilter',
											'languageSubFilter',
										],
									}"
                />
              </div>
              <div class="filter-action filter-action-lg ml-3">
                <multi-dropdown-list
                    :placeholder="this.$t('labels.local-office')"
                    componentId="localofficeFilter"
                    :filterLabel="this.$t('labels.local-office')"
                    dataField="localoffice.raw"
                    sortBy="asc"
                    :size="100"
                    :URLParams="true"
                    :showSearch="true"
                    :react="{
										and: [
											'officeFilter',
											'createdDateFilters',
											'userSubFilter',
											'prioritySubFilter',
											'ageSubFilter',
											'riskFilter',
											'categorySubFilter',
											'crimeSubFilter',
											'statusSubFilter',
											'languageSubFilter',
										],
									}"
                />
              </div>
              <div class="filter-action-sm ml-3">
                  <ReactiveComponent
                      componentId="createdDateFilters"
                      :URLParams="true"
                      :filterLabel="this.$t('labels.created-at')"
                      :react="{ and: [
                            'SearchResult',
                            'localofficeFilter',
                            'userSubFilter',
                            'prioritySubFilter',
                            'ageSubFilter',
                            'riskFilter',
                            'categorySubFilter',
                            'crimeSubFilter',
                            'statusSubFilter',
                            'languageSubFilter',
                          ],  }"
                  > 
                  <div slot-scope="{ aggregations, setQuery, value }">
                    <date-picker
                        opens="left"
                        :placeholder="$t('labels.created-at')"
                        :aggregations="aggregations"
                        :setQuery="setQuery"
                        :selectedValue="value"
                        :show-dropdowns="true"
                        dataField="created_at"
                        
                    />
                  </div>
                </ReactiveComponent>
              </div>
              <div class="filter-action ml-3 filter-action-sm">
                <multi-dropdown-list
                    :placeholder="this.$t('labels.priority')"
                    componentId="prioritySubFilter"
                    sortBy="asc"
                    :size="100"
                    :dataField="$field('priority')+'.raw'"
                    :filterLabel="this.$t('labels.priority')"
                    :URLParams="true"
                    :react="{
										and: [
											'officeFilter',
											'createdDateFilters',
											'localofficeFilter',
											'genderSubFilter',
											'ageSubFilter',
											'riskFilter',
											'userSubFilter',
											'categorySubFilter',
											'crimeSubFilter',
											'languageSubFilter',
										],
									}"
                />
              </div>
              <div class="filter-action ml-3 filter-action">
                <multi-dropdown-list
                    :placeholder="this.$t('labels.administrator')"
                    componentId="userSubFilter"
                    sortBy="asc"
                    :size="1000"
                    dataField="users.name.raw"
                    nestedField="users"
                    :filterLabel="this.$t('labels.administrator')"
                    :URLParams="true"
                    :react="{
										and: [
											'officeFilter',
											'createdDateFilters',
											'localofficeFilter',
											'genderSubFilter',
											'ageSubFilter',
											'prioritySubFilter',
											'riskFilter',
											'categorySubFilter',
											'crimeSubFilter',
											'languageSubFilter',
										],
									}"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="widget-body no-padding">
          <selected-filters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
        </div>
        <div class="widget-body no-padding">
          <reactive-component
              componentId="myColorPicker"
              :react="{
							and: [
								'riskPlaceholder',
								'languageSubFilter',
								'officeFilter',
								'prioritySubFilter',
								'localofficeFilter',
								'genderSubFilter',
								'ageSubFilter',
								'prioritySubFilter',
								'riskFilter',
								'categorySubFilter',
								'userSubFilter',
								'crimeSubFilter',
								'statusSubFilter',
                'createdDateFilters',
							],
						}"
              :defaultQuery="
							() => ({
								aggs: {
									users: {
										nested: {
											path: 'users',
										},
										aggs: {
											name: {
												terms: {
													field: 'users.name.raw',
													size: 100,
                          'order': { '_key': 'asc' }
												},
												aggs: {
                          supporter_status_tag: {
                            terms: {
                              field: 'users.supporter_status_tag.raw',
                              size: 1,
                              min_doc_count: 0,
                            },
                          },
													role: {
														terms: {
															field: 'users.'+$field('ticket_role')+'.raw',
															size: 100,
														},

														aggs: {
															status: {
																reverse_nested: {},
																aggs: {
																	tickets: {
																		terms: {
																			field: $field('ticketstatus')+'.raw',
																			size: 100,
																		},
																	},
																},
															},
														},
													},
												},
											},
										},
									},
								},
							})
						"
          >
            <div slot-scope="{ aggregations, hits, setQuery }">
              <div class="row">
                <div class="col-12">

                  <table class="vuetable" v-for="agg in aggregations.users.name.buckets" :key="agg.key">
                    <thead>
                    <tr>
                      <th width="20%" style="padding-left: 20px;"><status-icon v-if="agg.supporter_status_tag.buckets[0].doc_count > 0" style="position: absolute; left:20px;" :supporterStatusTag="agg.supporter_status_tag.buckets[0].key"></status-icon> {{ agg.key }}</th>
                      <th width="13%">{{ $t('labels.total') }}</th>
                      <th width="13%" :style="{ color: status.color }" v-for="status in ticketStatus" :key="status.id">
                        {{ $to(status,'label') }}
                      </th>
                    </tr>
                    </thead>
                    <tbody v-for="agg_role in agg.role.buckets" :key="agg_role.key">
                    <tr>
                      <td style="padding-left: 20px;">{{ agg_role.key }}</td>
                      <td>{{ agg_role.doc_count }}</td>
                      <td v-for="status in ticketStatus" :key="agg.key + status.id">
                        {{ getNumberOfTicketsFromStatus($to(status,'label'), agg_role.status.tickets.buckets) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </reactive-component>
        </div>
      </div>
    </div>
  </reactive-base>
</template>
<script>
import CustomTicketResults from '@/components/lists/ticket/CustomTicketResults';
import BojAPI from '@/api/boj';
import StatisticWidget from '@/components/widgets/StatisticWidget.vue';
import InfoBox from '@/components/InfoBox.vue';
import DatePicker from '@/components/DatePicker';
import StatusIcon from '@/components/member/StatusIcon';
import moment from 'moment';


export default {
  props: {
    title: String,
    defaultQuery: Object,
    enableFilter: {
      default: false,
      type: Boolean,
    },
    infoBox: String,
  },
  components: {
    CustomTicketResults,
    StatisticWidget,
    InfoBox,
    DatePicker,
    StatusIcon,
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      ticketStatus: [],
      getSortByOrder: true,
      getSortBy: '_score',
      showFilter: false,
    };
  },
  mounted() {
    if (
        Object.keys(this.$route.query).some(function (k) {
          return k.endsWith('SubFilter');
        })
    ) {
      this.showFilter = true;
    }

    this.getTicketStatuses();
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    visibleTicketStatus: function () {
      return this.ticketStatus.filter((i) => i.show_on_summary === 1);
    },
  },
  methods: {
    query: () => {
      return {
        query: {},
      };
    },

    pageChange() {
    },

    getNumberOfTicketsFromStatus(key, obj) {
      let n = obj.find((e) => e.key === key);
      if (n) {
        return n.doc_count;
      } else {
        return '0';
      }
    },

    getBucketCount(searchState, componentId, aggregationId, key) {
      if (Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {
        let aggregation = searchState[componentId].aggregations[aggregationId];
        if (Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations[aggregationId].buckets.find((item) => item.key === key);
          if (buckets) {
            return buckets.doc_count.toLocaleString('sv-SE');
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },

    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;
    },

    getTicketStatuses() {
      BojAPI.getStatuses().then((response) => {
        this.ticketStatus = response.data.data;
      });
    },
  },
};
</script>
